import React from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import ReactSwitch from "react-switch";

interface ModuleCardProps {
  moduleName: string;
  apartmentName?: string;
  active?: boolean;
  dateClosed?: string;
  price?: number;
  defaultPrice: number;
  onChange?: any;
  bundled?: boolean;
  bundlesSpecs?: { id: any; date_closed: string }[];
  hint?: any;
  freeMonthRemain?: any;
  blockedActivation?: any;
  blockedDeactivation?: any;
  isApartmentView?: boolean;
  modId?: string;
  priceCycles?: number
}

const ModuleCard: React.FC<ModuleCardProps> = ({
  moduleName,
  apartmentName,
  active,
  dateClosed,
  price,
  defaultPrice,
  onChange,
  bundled = false,
  bundlesSpecs,
  hint,
  freeMonthRemain,
  blockedActivation,
  blockedDeactivation,
  isApartmentView,
  modId,
  priceCycles
}) => {
  const { t, i18n } = useTranslation();

  const renderStatus = () => {
    if (active) {
      return (
        <div className="text-green-600">
          {dateClosed
            ? `${t("resv.activeUntil")} ${dayjs(dateClosed).format(
                "DD-MM-YYYY"
              )}`
            : t("general.active")}
        </div>
      );
    } else {
      return <div className="text-red-600">{t("general.inactive")}</div>;
    }
  };

  const renderMonthlyFee = () => {
    if (price === null || price === undefined) {
      return <span>&euro;{(defaultPrice / 100).toFixed(2)}</span>;
    } else {
      if (defaultPrice <= price) {
        return <span>&euro;{(price / 100).toFixed(2)}</span>;
      } else {
        return (
          <>
            <span className="line-through opacity-60 mr-2">
              &euro;{(defaultPrice / 100).toFixed(2)}
            </span>
            <span>
              &euro;{(price / 100).toFixed(2)}{" "}
              {
                priceCycles && priceCycles < 999 && (
                  <span>
                    {t("general.forNumMonths", {
                      number: priceCycles,
                    })}
                  </span>
                )
              }
              {freeMonthRemain && freeMonthRemain !== "None"
                ? `(${t("module.freeMonthRemain")}: ${freeMonthRemain})`
                : ""}
            </span>
          </>
        );
      }
    }
  };

  const renderAutomaticRenewal = () => {
    if (active) {
      if (dateClosed) {
        return <span className="text-red-600">{t("resv.disabled")}</span>;
      } else {
        return <span className="text-green-600">{t("resv.enabled")}</span>;
      }
    } else {
      return <span className="text-red-600">{t("resv.disabled")}</span>;
    }
  };

  const renderBundleMessage = () => {
    if (bundled) {
      return (
        <div className="border-t border-b border-gray-200 font-medium text-sm text-gray-600">
          <div>
            <span className="block">{t("module.bundleCombo")}:</span>
            {bundlesSpecs?.map((bundleSpec) => (
              <>
                <span className="uppercase">
                  * {t(`module.id${bundleSpec.id}`)}
                </span>{" "}
                {bundleSpec.date_closed && (
                  <span className="lowercase">
                    {t("resv.activeUntil")}{" "}
                    {dayjs(bundleSpec.date_closed).format("DD-MM-YYYY")}
                  </span>
                )}
              </>
            ))}
          </div>
        </div>
      );
    }
  };

  const bundleLogic = () => {
    if (bundled && bundlesSpecs && active) {
      const closingBundle: {
        id: any;
        date_closed: string;
      }[] = [];
      for (let bundle of bundlesSpecs) {
        if (!bundle.date_closed) {
          // return true;
          closingBundle.push(bundle);
        }
      }
      if (closingBundle.length === bundlesSpecs.length) {
        return true;
      } else {
        return false;
      }
    } else if (
      (!active && blockedActivation) ||
      (active && blockedDeactivation)
    )
      return true;
    else return false;
  };

  return (
    <div
      className={`${
        bundleLogic() ? "bg-gray-300" : "bg-white"
      } shadow-xl p-6 flex-1 rounded-2xl mt-5`}
    >
      <div className="flex flex-col lg:flex-row lg:items-center justify-between">
        <div className="flex-1 py-1 px-1 text-gray-700 font-medium border-t border-gray-200">
          <div className="uppercase">
            {t("general.module")} {moduleName}
            {apartmentName && ` - ${apartmentName}`}
          </div>
          <div className="py-2 border-b border-t border-gray-200 overflow-auto">
            <div className="md:flex justify-between items-center py-1">
              <div className="border-gray-200 text-gray-600 text-sm md:w-1/3">
                <div className="mb-1 font-medium">{t("apartment.status")}</div>
                {renderStatus()}
              </div>
              <div className="border-gray-200 text-gray-600 text-sm md:w-1/3">
                <div className="mb-1 font-medium">
                  {t("general.monthlyfee")}
                </div>
                {renderMonthlyFee()}
              </div>
              <div className="border-gray-200 text-gray-600 text-sm md:w-1/3">
                <div className="mb-1 font-medium">
                  {t("general.automaticRenewal")}
                </div>
                {renderAutomaticRenewal()}
              </div>
              <div className="border-gray-200 text-gray-600 text-sm">
                <ReactSwitch
                  checked={active ? (dateClosed ? false : true) : false}
                  disabled={bundleLogic()}
                  onChange={onChange}
                  onColor="#34d0ed"
                  size={1}
                />
              </div>
            </div>
          </div>
          {renderBundleMessage()}
          {hint}
          {isApartmentView && i18n.exists(`module.${modId}moduleFor`) ? (
            <div className="mt-3 font-normal text-xs text-gray-700">
              <p className="font-medium">{t("general.moduleFor")}</p> {t(`module.${modId}moduleFor`)}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ModuleCard;
