import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../reducers";

const initialState: {
  modalValueOverride: {
    data: {
      display: boolean;
      stopSellValue: boolean;
      closedToArrivalValue: boolean;
      closedToDepartureValue: boolean;
      type: any;
      roomType: any;
      ratePlan: any;
      restriction: any;
      currentPrice: number;
      priceValue: number;
      availabilityValue: number;
      dateRange: any;
      roomTypeId: any;
      propertyId: any;
      ratePlanId: any;
      minStayValue: number;
      maxStayValue: number;
      revenueValue?: number;
      minStayThroughValue: any;
    };
    status: string;
  };
} = {
  modalValueOverride: {
    data: {
      display: false,
      stopSellValue: false,
      closedToArrivalValue: false,
      closedToDepartureValue: false,
      type: null,
      roomType: null,
      ratePlan: null,
      restriction: "",
      currentPrice: 0,
      priceValue: 0,
      availabilityValue: 0,
      dateRange: null,
      roomTypeId: null,
      propertyId: null,
      ratePlanId: "",
      minStayValue: 0,
      minStayThroughValue: 0,
      maxStayValue: 0,
    },
    status: "idle",
  },
};

/* export const postAddPayMethodState = createAsyncThunk(
  "checkout/postAddPayMethod",
  async (body: any) => {
    try {
      const response: any = await postAddPayMethod(body);
      return response.data;
    } catch (error: any) {
      openToastify("danger", "something_missing", error?.data?.msg);
      return Promise.reject(error.data ? error.data : error);
    }
  }
); */

export const channelManagerSlice = createSlice({
  name: "channelManager",
  initialState,
  reducers: {
    setModalValueOverride: (state, action) => {
      state.modalValueOverride.data = action.payload;
    },
    resetModalValueOverride: (state) => {
      state.modalValueOverride.data = {
        display: false,
        stopSellValue: false,
        closedToArrivalValue: false,
        closedToDepartureValue: false,
        type: null,
        roomType: null,
        ratePlan: null,
        restriction: "",
        currentPrice: 0,
        priceValue: 0,
        availabilityValue: 0,
        dateRange: null,
        roomTypeId: null,
        propertyId: null,
        ratePlanId: "",
        minStayValue: 0,
        maxStayValue: 0,
        minStayThroughValue: 0
      };
    },
  },
  /* extraReducers(builder) {
    builder
      .addCase(postAddPayMethodState.pending, (state) => {
        state.addPayMethod.status = "loading";
      })
      .addCase(postAddPayMethodState.fulfilled, (state, action) => {
        state.addPayMethod.status = "success";
        state.addPayMethod.data = action.payload;
        localStorage.clear();
      })
      .addCase(postAddPayMethodState.rejected, (state, action) => {
        state.addPayMethod.status = "rejected";
        state.addPayMethod.data = action.error;
      });
  }, */
});

export const { setModalValueOverride, resetModalValueOverride } =
  channelManagerSlice.actions;
export const modalValueOverrideState = (state: RootState) =>
  state.channelManager.modalValueOverride;

export default channelManagerSlice.reducer;
