import React from "react";
import ReactTooltip from "react-tooltip";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setModalValueOverride } from "../../../../features/channelManager/channelManagerSlice";
import Icon from "../../../Icon";
import { CalendarDay } from "../../../../types";

type Props = {
  idi: any;
  idxOne: any;
  keyIdx: any;
  containerRefPrice: any;
  keyDi: any;
  desc: any;
  days: CalendarDay[];
  keys: any;
  localName: string;
  tempObj: any;
  findActiveRevenue: any;
  revenueManager: any;
  setIsDragging: any;
  setIsDraggingDays: any;
  setIsDraggingPrice: any;
  setValueManager: any;
  setRevenueModal: any;
  isDraggingPrice: any;
  handleMouseDownRate: any;
  handleMouseMoveRate: any;
  handleMouseUpRate: any;
  selectedDivsPrice: any;
  startPointPrice: any;
  type:
    | "rate"
    | "min_stay_arrival"
    | "stop_sell"
    | "closed_to_arrival"
    | "closed_to_departure"
    | "min_stay_through"
    | "max_stay";
  channelDerivate?: string;
  setChainedChannel?: any;
};

const PerRoomRate = (props: Props) => {
  const {
    idi,
    idxOne,
    keyIdx,
    containerRefPrice,
    keyDi,
    desc,
    days,
    keys,
    localName,
    tempObj,
    revenueManager,
    setIsDragging,
    setIsDraggingDays,
    setIsDraggingPrice,
    findActiveRevenue,
    setValueManager,
    setRevenueModal,
    handleMouseDownRate,
    handleMouseMoveRate,
    handleMouseUpRate,
    selectedDivsPrice,
    type,
    channelDerivate,
    setChainedChannel,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const dataTipType = (type: string) => {
    switch (type) {
      case "min_stay_arrival":
        return t(`channelManager.rate_plan.minStayArrival`);
      case "min_stay_through":
        return t(`channelManager.rate_plan.minStayThrough`);
      case "stop_sell":
        return t(`channelManager.rate_plan.stopSell`);
      case "closed_to_arrival":
        return t(`channelManager.rate_plan.closedToArrival`);
      case "closed_to_departure":
        return t(`channelManager.rate_plan.closedToDeparture`);
      case "max_stay":
        return t(`channelManager.rate_plan.maxStay`);
      default:
        return t(`channelManager.rate_plan.rate`);
    }
  };

  return (
    <div
      className="hover:bg-white flex md:text-sm text-xs border-t"
      key={idi}
      ref={(el) => {
        if (containerRefPrice) {
          return (containerRefPrice.current[idi + idxOne + keyIdx] = el);
        }
        return null;
      }}
      onMouseDown={(e) => {
        if (containerRefPrice) handleMouseDownRate(e, type);
      }}
      onMouseMove={(e) => {
        if (containerRefPrice) handleMouseMoveRate(e, type);
      }}
      onMouseUp={(e) => {
        if (containerRefPrice) handleMouseUpRate(type);
      }}
      style={{
        height: "50px",
      }}
    >
      <div
        className={`${
          channelDerivate ? "bg-yellow-200" : "bg-gray-100"
        } z-50 md:text-sm text-xs md:space-x-2 font-medium text-gray-600 flex items-start md:w-52 w-20 border-b md:py-2 border-r truncate md:px-5 flex-col`}
      >
        <div className="w-full">
          <div className="md:text-sm text-xs whitespace-nowrap flex md:gap-3 max-w-max">
            {type === "rate" && (
              <p className="truncate" data-tip={keyDi?.title}>
                <p className="flex md:flex-nowrap flex-wrap">
                  {channelDerivate ? (
                    <Icon
                      name={`assistant_connected_${channelDerivate}`}
                      size="16px"
                    />
                  ) : (
                    ""
                  )}
                  {keyDi?.title}
                </p>
              </p>
            )}
            <div className={`flex ${type !== "rate" && "h-4"}`}>
              {type === "rate" && (
                <>
                  {!channelDerivate && (
                    <>
                      <Icon name="occupancy" size="16px" />
                      {keyDi?.occupancy}
                    </>
                  )}
                </>
              )}
            </div>
            {setChainedChannel && (
              <div data-tip={t("calendar.connectedChannels")}>
                <Icon
                  name="chain"
                  size="16px"
                  className="cursor-pointer"
                  onClick={() => {
                    setChainedChannel((prev: any) => {
                      const previousValue = [...prev];
                      const title = keyDi?.title;
                      if (previousValue.includes(title)) {
                        const newValue = previousValue.filter(
                          (val) => val !== title
                        );
                        return newValue;
                      }
                      previousValue.push(title);
                      return previousValue;
                    });
                  }}
                />
              </div>
            )}
          </div>
          {!channelDerivate ? (
            <div className="flex justify-end items-end text-right">
              <div className="max-w-min" data-tip={dataTipType(type)}>
                {t(`channelManager.rate_plan.${type}`)}
              </div>
            </div>
          ) : (
            <div className="flex justify-end items-end text-right">
              <div
                className="max-w-min capitalize"
                data-tip={channelDerivate ? channelDerivate : dataTipType(type)}
              >
                {channelDerivate
                  ? channelDerivate
                  : t(`channelManager.rate_plan.${type}`)}
              </div>

              <div data-tip={t("calendar.derived")}>
                <Icon name="chain" size="16px" />
              </div>
            </div>
          )}
        </div>
        <ReactTooltip effect="float" />
      </div>
      <div className="flex-1 border-b flex relative place-content-center">
        <div
          className="absolute w-full h-full top-0 left-0 flex"
          key={desc + idi}
        >
          {days.map((day, index) => {
            const specificDate = `${day.date.slice(6, 10)}-${day.date.slice(
              3,
              5
            )}-${day.date.slice(0, 2)}`;
            const infoDate = keyDi?.date[specificDate];
            if(!infoDate) return (
              <div
                key={`${idi}${index}`}
                className={`flex-1 h-full border-r cursor-pointer text-center align-middle w-full truncate relative `}
              >
                <div
                  className={`text-xs opacity-50 max-w-max m-auto`}
                >
                  <span className="hidden"></span>
                </div>
                <span className="text-transparent hidden">
                </span>
              </div>
            );
            const editable = keyDi.editable;
            const isSelected = selectedDivsPrice.find(
              (idsPrice: any) => idsPrice.id === desc + specificDate + type
            );
            const isSelectedTwo =
              selectedDivsPrice.length > 0 &&
              dayjs(specificDate).isAfter(selectedDivsPrice[0].date) &&
              dayjs(specificDate).isBefore(
                selectedDivsPrice[selectedDivsPrice.length - 1].date
              ) &&
              keyDi.id === selectedDivsPrice[selectedDivsPrice.length - 1].id &&
              selectedDivsPrice[selectedDivsPrice.length - 1].type === type;
            return (
              <div
                onClick={() => {
                  if (editable) {
                    dispatch(
                      setModalValueOverride({
                        display: true,
                        type: keys,
                        roomType: localName,
                        ratePlan: keyDi?.title,
                        ratePlanId: desc,
                        restriction: type,
                        currentPrice: parseFloat(infoDate?.rate),
                        priceValue: parseFloat(infoDate?.rate),
                        availabilityValue: tempObj[keys][specificDate],
                        dateRange: {
                          from: specificDate,
                          to: specificDate,
                        },
                        roomTypeId: tempObj?.room_type_id,
                        propertyId: tempObj?.property_id,
                        stopSellValue: infoDate?.stop_sell,
                        closedToArrivalValue: infoDate?.closed_to_arrival,
                        closedToDepartureValue: infoDate?.closed_to_departure,
                        minStayValue: infoDate?.min_stay_arrival,
                        minStayThroughValue: infoDate?.min_stay_through,
                        maxStayValue: infoDate?.max_stay,
                        revenueValue:
                          revenueManager?.results?.payload?.rates?.find(
                            (d: any) => d.date === specificDate
                          )?.rate,
                      })
                    );
                    setIsDragging(false);
                    setIsDraggingDays(false);
                    setIsDraggingPrice({
                      type: "",
                      active: false,
                    });
                  }
                }}
                key={`${idi}${index}`}
                className={`flex-1 h-full border-r cursor-pointer text-center align-middle w-full truncate relative ${
                  infoDate?.availability && !infoDate?.stop_sell
                    ? editable
                      ? isSelected || isSelectedTwo
                        ? "bg-cyan-200"
                        : "hover:bg-gray-100"
                      : "text-gray-400 cursor-not-allowed opacity-75 "
                    : editable
                    ? isSelected || isSelectedTwo
                      ? "bg-cyan-200"
                      : "bg-red-300 hover:bg-red-400"
                    : "bg-red-300 hover:bg-red-400 text-gray-400 cursor-not-allowed opacity-75 "
                }`}
              >
                <div
                  className={`text-xs opacity-50 max-w-max m-auto ${
                    type === "rate" &&
                    findActiveRevenue &&
                    revenueManager &&
                    revenueManager.results &&
                    revenueManager.results.payload?.rates?.find(
                      (d: any) => d.date === specificDate
                    )
                      ? "block"
                      : "hidden"
                  } `}
                >
                  {findActiveRevenue &&
                    revenueManager &&
                    revenueManager.results &&
                    revenueManager.results.payload?.rates?.find(
                      (d: any) => d.date === specificDate
                    )?.rate}
                  <span className="hidden">|||</span>
                </div>
                {![
                  "closed_to_arrival",
                  "closed_to_departure",
                  "stop_sell",
                ].includes(type)
                  ? infoDate[type] && parseFloat(infoDate[type])
                  : infoDate[type]
                  ? t("general.yes")
                  : t("general.no")}
                <span className="text-transparent hidden">
                  {`|||${
                    infoDate?.rate && parseFloat(infoDate?.rate)
                  }|||${specificDate}|||${keyDi?.title}|||${desc}|||${
                    infoDate?.stop_sell
                  }|||${infoDate?.closed_to_arrival}|||${
                    infoDate?.closed_to_departure
                  }|||${
                    infoDate?.min_stay_arrival
                  }|||${infoDate?.min_stay_through}|||${infoDate?.max_stay}|||${editable}|||${keys}|||`}
                </span>
                {type === "rate" &&
                  findActiveRevenue &&
                  revenueManager &&
                  revenueManager.results &&
                  revenueManager.results?.payload?.rates?.find(
                    (d: any) => d.date === specificDate
                  ) && (
                    <div
                      className="max-w-max absolute right-0 lg:top-0 bottom-0 h-4"
                      onMouseEnter={(e) => {
                        setValueManager(
                          e,
                          revenueManager,
                          specificDate,
                          infoDate?.rate && parseFloat(infoDate?.rate)
                        );
                      }}
                      onMouseLeave={(e) =>
                        setRevenueModal({
                          x: 0,
                          y: 0,
                          display: false,
                        })
                      }
                    >
                      <Icon name="information-circle" size="14px" />
                    </div>
                  )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PerRoomRate;
