import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { updateUserAccess, deleteUserAccess } from '../../shared/queries';
import { BaseSelectItem, LocalAccess } from '../../types';
import SelectInput from '../SelectInput';
import Button from '../../components/Button';
import DeleteDialog from '../../components/DeleteDialog';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';

export type ApartmentUserPermissionProps = {
  access: LocalAccess;
  refetch?: any;
};

export default function ApartmentUserPermission({
  access,
  refetch,
}: ApartmentUserPermissionProps) {
  const { t } = useTranslation();
  const [userAccess, setUserAccess] = useState<LocalAccess>(access);
  const [showDelete, setShowDelete] = useState(false);
    const { profile } = useSelector((state: RootState) => state.login);
  
  const yesNoList = [
    {
      label: t('general.yes'),
      value: 2,
    },
    {
      label: t('general.no'),
      value: 0,
    },
  ];

  const permissionList = [
    {
      label: 'No',
      value: 0,
    },
    {
      label: t('apartmentPermission.read'),
      value: 1,
    },
    {
      label: t('apartmentPermission.write'),
      value: 2,
    },
  ];

  const deleteMutation = useMutation(() => deleteUserAccess(userAccess), {
    onSuccess: () => {
      toast.success(t('general.operationCompleted') + ' ' + userAccess.user.name);
      setShowDelete(false);
      if (refetch) refetch();
    },
  });

  const mutation = useMutation(
    (_userAccess: LocalAccess) => updateUserAccess(_userAccess),
    {
      onSuccess: (resp, _userAccess) => {
        toast.success(
          t('apartmentPermission.permissionUpdated') + ' ' + userAccess.user.name
        );
        setUserAccess({ ..._userAccess });
      },
    }
  );

  return (
    <div className={'border-b pb-6 mb-6 border-gray-300'}>
      <DeleteDialog
        askConfirmation={true}
        confirmationKey={t('general.iConfirm')}
        isLoading={deleteMutation.isLoading}
        visible={showDelete}
        message={t('reservations.delete')}
        onCancel={() => setShowDelete(false)}
        onConfirm={() => {
          deleteMutation.mutate();
        }}
      />
      <div className="flex w-full justify-between">
        <div className={'flex items-center mb-4'}>
          <div
            className={
              'mr-3 cursor-pointer hover:ring hover:ring-2 uppercase ring-cyan-200 bg-cyan-100 rounded-full w-10 h-10 text-cyan-600 font-medium text-center flex items-center justify-center'
            }
          >
            {userAccess.user?.name ? userAccess.user?.name?.charAt(0) : ''}
          </div>
          <div>
            <div className={'text-lg text-gray-800 font-semibold'}>
              {userAccess.user.name}
            </div>
            <div className={'text-sm text-gray-600'}>{userAccess.user.mail}</div>
          </div>
        </div>
        <div>
          <Button
            onClick={() => setShowDelete(true)}
            color={'negative'}
            label={t('general.delete')}
            flat
            right-0
          ></Button>
        </div>
      </div>

      <div className={'ml-12'}>
        <div className={'flex items-center mb-2'}>
          <div className={'mr-6 w-52'}>
            {t('apartmentPermission.openingAccess')}
          </div>
          <SelectInput<BaseSelectItem, false>
            className={'w-60'}
            value={yesNoList.find((pl) => pl.value === userAccess.access_tools)}
            options={yesNoList}
            onChange={(item) => {
              if (item) {
                mutation.mutate({
                  ...userAccess,
                  access_tools: item.value,
                });
              }
            }}
          />
        </div>
        <div className={'flex items-center mb-2'}>
          <div className={'mr-6 w-52'}>
            {t('apartmentPermission.reservationAccess')}
          </div>
          <SelectInput<BaseSelectItem, false>
            className={'w-60'}
            value={permissionList.find(
              (pl) => pl.value === userAccess.access_reservations
            )}
            options={permissionList}
            onChange={(item) => {
              if (item) {
                mutation.mutate({
                  ...userAccess,
                  access_reservations: item.value,
                });
              }
            }}
          />
        </div>
        <div className={'flex items-center mb-2'}>
          <div className={'mr-6 w-52'}>
            {t('apartmentPermission.paymentAccess')}
          </div>
          <SelectInput<BaseSelectItem, false>
            className={'w-60'}
            value={yesNoList.find(
              (pl) => pl.value === userAccess.access_billing
            )}
            options={yesNoList}
            onChange={(item) => {
              if (item) {
                mutation.mutate({
                  ...userAccess,
                  access_billing: item.value,
                });
              }
            }}
          />
        </div>
        <div className={'flex items-center mb-2'}>
          <div className={'mr-6 w-52'}>
            {t('apartmentPermission.billingAccess')}
          </div>
          <SelectInput<BaseSelectItem, false>
            className={'w-60'}
            value={yesNoList.find(
              (pl) => pl.value === userAccess.access_invoice
            )}
            options={yesNoList}
            onChange={(item) => {
              if (item) {
                mutation.mutate({
                  ...userAccess,
                  access_invoice: item.value,
                });
              }
            }}
          />
        </div>
        <div className={'flex items-center mb-2'}>
          <div className={'mr-6 w-52'}>
            {t('apartmentPermission.apartmentDetails')}
          </div>
          <SelectInput<BaseSelectItem, false>
            className={'w-60'}
            value={permissionList.find(
              (pl) => pl.value === userAccess.access_property
            )}
            options={permissionList}
            onChange={(item) => {
              if (item) {
                mutation.mutate({
                  ...userAccess,
                  access_property: item.value,
                });
              }
            }}
          />
        </div>

        <div className={'flex items-center mb-2'}>
          <div className={'mr-6 w-52'}>
            {t('apartmentPermission.accountCustomization')}
          </div>
          <SelectInput<BaseSelectItem, false>
            className={'w-60'}
            value={permissionList.find(
              (pl) => pl.value === userAccess.access_custom
            )}
            options={permissionList}
            onChange={(item) => {
              if (item) {
                mutation.mutate({
                  ...userAccess,
                  access_custom: item.value,
                });
              }
            }}
          />
        </div>

        <div className={'flex items-center mb-2'}>
          <div className={'mr-6 w-52'}>
            {t('apartmentPermission.accessDomotics')}
          </div>
          <SelectInput<BaseSelectItem, false>
            className={'w-60'}
            value={permissionList.find(
              (pl) => pl.value === userAccess.access_domotics
            )}
            options={permissionList}
            onChange={(item) => {
              if (item) {
                mutation.mutate({
                  ...userAccess,
                  access_domotics: item.value,
                });
              }
            }}
          />
        </div>
        <div className={'flex items-center  mb-2'}>
          <div className={'mr-6 w-52'}>
            {t('apartmentPermission.access_integrations')}
          </div>
          <SelectInput<BaseSelectItem, false>
            className={'w-60'}
            value={permissionList.find(
              (pl) => pl.value === userAccess.access_integrations
            )}
            options={permissionList}
            onChange={(item) => {
              if (item) {
                mutation.mutate({
                  ...userAccess,
                  access_integrations: item.value,
                });
              }
            }}
          />
        </div>
        {profile &&
            profile.user_key &&
            [
              "VT1xfjPoskpbCWGj0000000000000000",
              "DV8FOzJ5cjMSI67t0000000000000000",
              "FV1D9yGU1Ct5tWEA0000000000000000",
              "CC25u7xWn6HgHBep0000000000000000",
              'AL6KsXYdsiuSPLmh0000000000000000',
              'Ls88kabA1e7AEayz0000000000000000',
              'MR2Hw64QtwEp7Azq0000000000000000',
              'NC0Yh8Squms61a190000000000000000',
              'D20hADPSSQmRfbmf0000000000000000',
              "0XW4Y4UCOjL6Vl000000000000000000"
            ].includes(profile.user_key) && (
        <div className={'flex items-center'}>
          <div className={'mr-6 w-52'}>
            {t('apartmentPermission.access_assistant_data')}
          </div>
          <SelectInput<BaseSelectItem, false>
            className={'w-60'}
            value={permissionList.find(
              (pl) => pl.value === userAccess.access_assistant_data
            )}
            options={permissionList}
            onChange={(item) => {
              if (item) {
                mutation.mutate({
                  ...userAccess,
                  access_assistant_data: item.value,
                });
              }
            }}
          />
        </div>)}
      </div>
    </div>
  );
}
