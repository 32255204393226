import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SelectInput from "../SelectInput";
import { BaseSelectItem } from "../../types";
import { useMutation, useQuery } from "react-query";
import {
  activateModule,
  fetchAllModules,
  fetchApartmentSettings,
  fetchContracts,
  fetchCustomCheckins,
  fetchCustomEmails,
  sendApartmentSettings,
  fetchV3AllLocals,
  fetchV3MultiSelect,
  setMultiLocals,
} from "../../shared/queries";
import Checkbox from "../Checkbox";
import Button from "../Button";
import { moduleId } from "../../shared/utils/config";
import Modal from "../Modal";
import ModalCard from "../ModalCard";
import ModalSection from "../ModalSection";
import TextInput from "../TextInput";
import ModalActions from "../ModalAction";
import ModuleCard from "../module/ModuleCard";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import FormField from "../FormField";
import MultiSelect from "../apartment/MultiSelect";

type Props = {
  data: any;
};

interface SettingsType {
  local_key: string;
  chk_mail: boolean;
  chk_sms: boolean;
  chk_sms_done: boolean;
  chk_cc: boolean;
  chk_documents: boolean;
  chk_asknumguests: boolean;
  chk_contract: boolean;
  chk_otp: boolean;
  chk_photo: boolean;
  chk_photo_all: boolean;
  chk_citytax: boolean;
  chk_citytax_chargeguest: boolean;
  chk_paystay: boolean;
  chk_paystay_chargeguest: boolean;
  chk_deposit: boolean;
  chk_deposit_chargeguest: boolean;
  deposit_amount: number;
  chk_whatsapp: boolean;
  chk_whatsapp_done: boolean;
  chk_docs_auto_confirmation: boolean;
  require_invoice: boolean;
  cust_email_ptr: number;
  cust_checkin_ptr: number;
  cust_contract_ptr: number;
  notify_now: boolean;
  notify_1days: boolean;
  notify_3days: boolean;
  notify_7days: boolean;
  notify_done_1days: boolean;
  notify_mail_guest_active: boolean;
}

interface ModalView {
  id: number | null;
  confirmValue: string;
  active: boolean;
  price: number;
  defaultPrice: number;
  priceCycles: number;
}

const ApartmentSettings = (props: Props) => {
  const { local_key, country_code } = props.data;
  const { t } = useTranslation();
  const confirmationKey = t("general.iConfirm");
  const [settingsData, setSettingsData] = useState<SettingsType>({
    local_key: local_key,
    chk_mail: false,
    chk_sms: false,
    chk_sms_done: false,
    chk_cc: false,
    chk_documents: false,
    chk_asknumguests: false,
    chk_contract: false,
    chk_otp: false,
    chk_photo: false,
    chk_photo_all: false,
    chk_citytax: false,
    chk_citytax_chargeguest: false,
    chk_paystay: false,
    chk_paystay_chargeguest: false,
    chk_deposit: false,
    chk_deposit_chargeguest: false,
    deposit_amount: 0,
    chk_whatsapp: false,
    chk_whatsapp_done: false,
    chk_docs_auto_confirmation: false,
    require_invoice: false,
    cust_email_ptr: 0,
    cust_checkin_ptr: 0,
    cust_contract_ptr: 0,
    notify_now: false,
    notify_1days: false,
    notify_3days: false,
    notify_7days: false,
    notify_done_1days: false,
    notify_mail_guest_active: false,
  });
  const [moduleList, setModuleList] = useState([
    {
      name: t(`module.id${moduleId.docs_auto_confirmation}`).toUpperCase(),
      linkId: moduleId.docs_auto_confirmation,
      disableButton: true,
    },
    {
      name: t(`module.id${moduleId.sms}`).toUpperCase(),
      linkId: moduleId.sms,
      disableButton: true,
    },
    {
      name: t(`module.id${moduleId.whatsapp}`).toUpperCase(),
      linkId: moduleId.whatsapp,
      disableButton: true,
    },
    {
      name: t(`module.id${moduleId.sms_whatsapp}`).toUpperCase(),
      linkId: moduleId.sms_whatsapp,
      disableButton: true,
    },
    {
      name: t(`module.id${moduleId.sms_done}`).toUpperCase(),
      linkId: moduleId.sms_done,
      disableButton: true,
    },
    {
      name: t(`module.id${moduleId.whatsapp_done}`).toUpperCase(),
      linkId: moduleId.whatsapp_done,
      disableButton: true,
    },
    {
      name: t(`module.id${moduleId.sms_whatssapp_done}`).toUpperCase(),
      linkId: moduleId.sms_whatssapp_done,
      disableButton: true,
    },
    {
      name: t(`module.id${moduleId.contract}`).toUpperCase(),
      linkId: moduleId.contract,
      disableButton: true,
    },
    {
      name: t(`module.id${moduleId.pa}`).toUpperCase(),
      linkId: moduleId.pa,
      disableButton: true,
    },
    {
      name: t(`module.id${moduleId.paytourist}`).toUpperCase(),
      linkId: moduleId.paytourist,
      disableButton: true,
    },
    {
      name: t(`module.id${moduleId.city_tax}`).toUpperCase(),
      linkId: moduleId.city_tax,
      disableButton: true,
    },
    {
      name: t(`module.id${moduleId.deposit}`).toUpperCase(),
      linkId: moduleId.deposit,
      disableButton: true,
    },
    {
      name: t(`module.id${moduleId.auto_istat}`).toUpperCase(),
      linkId: moduleId.auto_istat,
      disableButton: true,
    },
  ]);
  const [modalView, setModalView] = useState<ModalView>({
    id: null,
    confirmValue: "",
    active: false,
    price: 0,
    defaultPrice: 0,
    priceCycles: 999,
  });
  const { isLoading: isLoadingSets, refetch: refetchSets } = useQuery(
    ["settings", local_key],
    () => fetchApartmentSettings(local_key),
    {
      enabled: local_key !== null,
      onSuccess: (data) => {
        setSettingsData({
          local_key: local_key,
          chk_mail: data.result.chk_mail,
          chk_sms: data.result.chk_sms,
          chk_sms_done: data.result.chk_sms_done,
          chk_cc: data.result.chk_cc,
          chk_documents: data.result.chk_documents,
          chk_asknumguests: data.result.chk_asknumguests,
          chk_contract: data.result.chk_contract,
          chk_otp: data.result.chk_otp,
          chk_photo: data.result.chk_photo,
          chk_photo_all: data.result.chk_photo_all,
          chk_citytax: data.result.chk_citytax,
          chk_citytax_chargeguest: data.result.chk_citytax_chargeguest,
          chk_paystay: data.result.chk_paystay,
          chk_paystay_chargeguest: data.result.chk_paystay_chargeguest,
          chk_deposit: data.result.chk_deposit,
          chk_deposit_chargeguest: data.result.chk_deposit_chargeguest,
          deposit_amount: data.result.deposit_amount,
          chk_whatsapp: data.result.chk_whatsapp,
          chk_whatsapp_done: data.result.chk_whatsapp_done,
          chk_docs_auto_confirmation: data.result.chk_docs_auto_confirmation,
          require_invoice: data.result.require_invoice,
          cust_email_ptr: data.result.cust_email_ptr ?? 0,
          cust_checkin_ptr: data.result.cust_checkin_ptr ?? 0,
          cust_contract_ptr: data.result.cust_contract_ptr ?? 0,
          notify_now: data.result.notify_now,
          notify_1days: data.result.notify_1days,
          notify_3days: data.result.notify_3days,
          notify_7days: data.result.notify_7days,
          notify_done_1days: data.result.notify_done_1days,
          notify_mail_guest_active: data.result.notify_mail_guest_active,
        });
      },
    }
  );
  const { data: emails, isLoading: isLoadingEmails } = useQuery(
    ["emails", local_key],
    () => fetchCustomEmails(local_key, true),
    {
      enabled: local_key !== null,
    }
  );
  const { data: contracts, isLoading: isLoadingContracts } = useQuery(
    ["contracts", local_key],
    () => fetchContracts(local_key, true),
    {
      enabled: local_key !== null,
    }
  );
  const { data: checkins, isLoading: isLoadingCheckins } = useQuery(
    ["checkins", local_key],
    () => fetchCustomCheckins(),
    {
      enabled: local_key !== null,
    }
  );
  const { data: dataModules, refetch: refetchModules } = useQuery<any>(
    ["modulesSettings", local_key],
    () => {
      if (local_key) {
        return fetchAllModules(local_key).then((data: any) => {
          setModuleList((prev) => {
            const temporary = prev.map((mod) => {
              const tempMod = data?.modules?.find(
                (d: any) => d.id === mod.linkId
              );
              if (tempMod && tempMod.active) {
                return {
                  ...mod,
                  disableButton: false,
                };
              }
              return {
                ...mod,
              };
            });
            return temporary;
          });
          return data;
        });
      }
    }
  );
  const changeData = (
    property: keyof SettingsType,
    value?: any,
    skip_bool?: boolean
  ) => {
    if (!property && !value) return;
    if (!value) {
      setSettingsData((prev) => {
        return {
          ...prev,
          [property]: skip_bool ? value : !prev[property as keyof SettingsType],
        };
      });
    } else {
      setSettingsData((prev) => {
        return {
          ...prev,
          [property]: value,
        };
      });
    }
  };
  const openModalView = (idMod: number) => {
    const data = dataModules.modules.find((mod: any) => mod.id === idMod);
    const price = data.self_price;
    const defaultPrice = data.default_price;
    window.dataLayer.push({
      event: "clic_attiva_modulo_impostazioni",
      gtm: {
        id_modulo: idMod,
        location: country_code?.toLowerCase(),
        value:
          price !== null
            ? price >= 0
              ? (price / 100).toFixed(2)
              : (defaultPrice / 100).toFixed(2)
            : (defaultPrice / 100).toFixed(2),
      },
    });

    setModalView((prev) => {
      return {
        ...prev,
        id: idMod,
        price: price,
        defaultPrice: defaultPrice,
        priceCycles: data.self_price_cycles,
      };
    });
  };
  const activateMutation = useMutation(
    (data: { local_keys: string[]; module_id: number }) =>
      activateModule(data.local_keys, data.module_id),
    {
      onSuccess: () => {
        window.dataLayer.push({
          event: "modulo_attivato_impostazioni",
          gtm: {
            id_modulo: modalView.id,
            value: modalView.price
              ? modalView.price > 0
                ? (modalView.price / 100).toFixed(2)
                : (modalView.defaultPrice / 100).toFixed(2)
              : (modalView.defaultPrice / 100).toFixed(2),
          },
        });
        toast.success(t("general.requestCompleted"));
        refetchModules();
        setModalView({
          id: null,
          confirmValue: "",
          active: false,
          price: 0,
          defaultPrice: 0,
          priceCycles: 999,
        });
      },
      onError: (error: Error) => {
        toast.error(t("general.requestError"));
      },
    }
  );
  const sendSettingsMutation = useMutation(
    (data: SettingsType) => sendApartmentSettings(data),
    {
      onSuccess: () => {
        toast.success(t("general.requestCompleted"));
        refetchSets();
        refetchModules();
        setModalView({
          id: null,
          confirmValue: "",
          active: false,
          price: 0,
          defaultPrice: 0,
          priceCycles: 999,
        });
        refetchLocalsAll();
      },
      onError: (error: Error) => {
        toast.error(t("general.requestError"));
      },
    }
  );

  const { data: locals, } = useQuery(
    "localsv3",
    fetchV3AllLocals
  );

  const { data: localsAll, refetch: refetchLocalsAll } = useQuery(
    ["localsAllv3"],
    () => fetchV3MultiSelect({ action: "all", local_key: local_key })
  );

  const saveAll = useMutation(
    (body: { locals: any[]; action: string; local_key: string }) =>
      setMultiLocals(body),
    {
      onSuccess: () => {
        toast.success(t("general.multiSetDone"));
        refetchLocalsAll();
      },
      onError: (error: Error) => {
        toast.error(t(error?.message ? error.message : "general.requestError"));
      },
    }
  );

  const getByTitle = (cCode: string, text: string) => {
    if (text === "subtitle") {
      switch (cCode) {
        case "ES":
          return t("apartmentSettings.iterBurocracySpain");
        case "PT":
          return t("apartmentSettings.iterBurocracySef");
        case "CZ":
          return t("apartmentSettings.iterBurocracyUby");
        default:
          return t("apartmentSettings.iterBurocracyPa");
      }
    }
    if (text === "title") {
      switch (cCode) {
        case "PT":
          return "Sef";
        case "ES":
          return "Policía Nacional; Mossos d'Esquadra; Guardia Civil";
        case "CZ":
          return "Uby";
        default:
          return t("apartmentSettings.pa");
      }
    }
  };

  if (isLoadingSets) {
    return (
      <>
        <div className={"px-1 flex-1"}>
          <Skeleton />
        </div>
        <div className={"px-1 flex-1"}>
          <Skeleton />
        </div>
        <div className={"px-1 flex-1"}>
          <Skeleton />
        </div>
        <div className={"px-1 flex-1"}>
          <Skeleton />
        </div>
        <div className={"px-1 flex-1"}>
          <Skeleton />
        </div>
      </>
    );
  }
  return (
    <>
      {/* INTRODUZIONE  */}
      <div className="space-y-3 text-sm">
        <p>{t("apartmentSettings.introduction1")}</p>
        <p className="font-bold italic">
          {t("apartmentSettings.introduction2")}
        </p>
      </div>

      <MultiSelect
        className={"px-2 flex-1 mb-6"}
        label={"apartment.selectedLocals"}
        title={"apartmentAll.applyToOtherLocals"}
        isCleared
        objStateStart={
          localsAll && localsAll.results
            ? localsAll.results.map((l) => {
                return {
                  label: l.name,
                  value: l.local_key,
                };
              })
            : []
        }
        objOptions={
          locals && locals.results
            ? locals.results.map((l) => {
                return {
                  label: l.name,
                  value: l.local_key,
                };
              })
            : []
        }
        onButtonClick={(selectedLocals) =>
          saveAll.mutate({
            locals: selectedLocals,
            action: "all",
            local_key: local_key,
          })
        }
      ></MultiSelect>

      {/* INTRODUZIONE  */}
      <div className="border-b border-gray-300 h-full my-3"></div>
      {/* COMUNICAZIONE  */}
      <>
        <div className="text-cyan-600 text-lg italic font-semibold">
          {t("apartmentSettings.communications")}
        </div>
        <div className="my-3 space-y-2 text-sm">
          <div className="text-sm my-2">
            <Checkbox
              className={"max-w-max"}
              label={t("apartmentSettings.chk_mail")}
              checked={settingsData.chk_mail}
              onChange={() => {
                changeData("chk_mail");
              }}
            />
          </div>
          <p>{t("apartmentSettings.cust_email_ptr")}:</p>
          <div className="lg:w-1/3 w-full">
            <SelectInput<BaseSelectItem, false>
              isDisabled={!settingsData.chk_mail}
              value={{
                label:
                  emails?.results?.find(
                    (mail) => mail.id === settingsData.cust_email_ptr
                  )?.objkey ?? "Vikey default",
                value:
                  emails?.results?.find(
                    (mail) => mail.id === settingsData.cust_email_ptr
                  )?.id ?? 0,
              }}
              onChange={(item) => {
                if (item) {
                  changeData("cust_email_ptr", item.value, true);
                }
              }}
              placeholder={"Seleziona email..."}
              isLoading={isLoadingEmails}
              options={
                emails?.results
                  ? [
                      ...emails.results.map((c) => {
                        return {
                          label: c.objkey,
                          value: c.id,
                        };
                      }),
                      {
                        label: "Vikey default",
                        value: 0,
                      },
                    ]
                  : []
              }
            />
          </div>
        </div>
        {/* CHECKBOX LIST */}
        <>
          <p className="text-sm">{t("apartmentSettings.whenToSendEmail")}</p>
          <div className="text-sm mt-2">
            <Checkbox
              className={"max-w-max"}
              label={t("apartmentSettings.chk_cc")}
              disabled={!settingsData.chk_mail}
              checked={settingsData.chk_mail && settingsData.chk_cc}
              onChange={() => {
                changeData("chk_cc");
              }}
            />
          </div>
          <div className="text-sm">
            <Checkbox
              className={"max-w-max"}
              disabled={!settingsData.chk_mail}
              label={t("apartmentSettings.notify_mail_guest_active")}
              checked={
                settingsData.chk_mail && settingsData.notify_mail_guest_active
              }
              onChange={() => {
                changeData("notify_mail_guest_active");
              }}
            />
          </div>
          <div className="text-sm">
            <Checkbox
              className={"max-w-max"}
              // value notify_now legato a chkmail
              disabled={!settingsData.chk_mail}
              label={t("apartmentSettings.notify_now")}
              checked={settingsData.chk_mail && settingsData.notify_now}
              onChange={() => {
                changeData("notify_now");
              }}
            />
          </div>
          <div className="text-sm">
            <Checkbox
              className={"max-w-max"}
              disabled={!settingsData.chk_mail}
              label={t("apartmentSettings.notify_1days")}
              checked={settingsData.chk_mail && settingsData.notify_1days}
              onChange={() => {
                changeData("notify_1days");
              }}
            />
          </div>
          <div className="text-sm">
            <Checkbox
              className={"max-w-max"}
              disabled={!settingsData.chk_mail}
              checked={settingsData.chk_mail && settingsData.notify_3days}
              label={t("apartmentSettings.notify_3days")}
              onChange={() => {
                changeData("notify_3days");
              }}
            />
          </div>
          <div className="text-sm">
            <Checkbox
              className={"max-w-max"}
              disabled={!settingsData.chk_mail}
              checked={settingsData.chk_mail && settingsData.notify_7days}
              label={t("apartmentSettings.notify_7days")}
              onChange={() => {
                changeData("notify_7days");
              }}
            />
          </div>
          <div className="text-sm">
            <Checkbox
              className={"max-w-max"}
              disabled={!settingsData.chk_mail}
              checked={settingsData.chk_mail && settingsData.notify_done_1days}
              label={t("apartmentSettings.notify_done_1days")}
              onChange={() => {
                changeData("notify_done_1days");
              }}
            />
          </div>
        </>
        {/* CHECKBOX LIST */}
        {/* CARD SMS WHATSAPP REMIND CHECK IN ONLINE */}
        <div className="lg:flex justify-between shadow-md bg-cyan-200 px-8 py-4 rounded-xl mt-3">
          <div className="text-sm">
            <p>{t("apartmentSettings.want_sms_whatsapp1")}</p>
            <p className="text-xs">
              {t("apartmentSettings.want_sms_whatsapp2")}
            </p>
            <div className="flex justify-start text-sm mt-3 gap-5">
              <div>
                <Checkbox
                  className={"max-w-max"}
                  label={t("apartmentSettings.chk_sms")}
                  disabled={
                    moduleList.find((mod) => mod.linkId === moduleId.sms)
                      ?.disableButton
                  }
                  checked={settingsData.chk_sms}
                  onChange={() => {
                    changeData("chk_sms");
                  }}
                />
              </div>
              <div>
                <Checkbox
                  className={"max-w-max"}
                  label={t("apartmentSettings.chk_whatsapp")}
                  disabled={
                    moduleList.find((mod) => mod.linkId === moduleId.whatsapp)
                      ?.disableButton
                  }
                  checked={settingsData.chk_whatsapp}
                  onChange={() => {
                    changeData("chk_whatsapp");
                  }}
                />
              </div>
            </div>
          </div>
          <div className="lg:mt-0 mt-3">
            <Button
              // gtmClass={`impostazioni_attiva_modulo_${moduleId.sms_whatsapp}_${country_code.toLowerCase()}`}
              label={
                moduleList.find((mod) => mod.linkId === moduleId.sms_whatsapp)
                  ?.disableButton
                  ? `${t("apartmentSettings.activeModule")!}`
                  : t("apartmentSettings.moduleAlreadyActive")
              }
              disabled={
                !moduleList.find((mod) => mod.linkId === moduleId.sms_whatsapp)
                  ?.disableButton
              }
              size={"small"}
              onClick={() => {
                openModalView(moduleId.sms_whatsapp);
              }}
            />
          </div>
        </div>
        {/* CARD SMS WHATSAPP REMIND CHECK IN ONLINE */}
        {/* CARD SMS WHATSAPP DONE */}
        <div className="lg:flex justify-between shadow-md bg-cyan-200 px-8 py-4 rounded-xl mt-3">
          <div className="text-sm">
            <p>{t("apartmentSettings.want_sms_whatsapp_done1")}</p>
            <p className="text-xs">
              {t("apartmentSettings.want_sms_whatsapp_done2")}
            </p>
            <div className="flex justify-start text-sm mt-3 gap-5">
              <div>
                <Checkbox
                  className={"max-w-max"}
                  label={t("apartmentSettings.chk_sms_done")}
                  checked={settingsData.chk_sms_done}
                  disabled={
                    moduleList.find((mod) => mod.linkId === moduleId.sms_done)
                      ?.disableButton
                  }
                  onChange={() => {
                    changeData("chk_sms_done");
                  }}
                />
              </div>
              <div>
                <Checkbox
                  className={"max-w-max"}
                  label={t("apartmentSettings.chk_whatsapp_done")}
                  checked={settingsData.chk_whatsapp_done}
                  disabled={
                    moduleList.find(
                      (mod) => mod.linkId === moduleId.whatsapp_done
                    )?.disableButton
                  }
                  onChange={() => {
                    changeData("chk_whatsapp_done");
                  }}
                />
              </div>
            </div>
          </div>
          <div className="lg:mt-0 mt-3">
            <Button
              // gtmClass={`impostazioni_attiva_modulo_${moduleId.sms_whatssapp_done}_${country_code.toLowerCase()}`}
              label={
                moduleList.find(
                  (mod) => mod.linkId === moduleId.sms_whatssapp_done
                )?.disableButton
                  ? `${t("apartmentSettings.activeModule")!}`
                  : t("apartmentSettings.moduleAlreadyActive")
              }
              disabled={
                !moduleList.find(
                  (mod) => mod.linkId === moduleId.sms_whatssapp_done
                )?.disableButton
              }
              size={"small"}
              onClick={() => {
                openModalView(moduleId.sms_whatssapp_done);
              }}
            />
          </div>
        </div>
        {/* CARD SMS WHATSAPP DONE */}

        <div className="mt-5 text-sm">
          <p>{t("apartmentSettings.cust_checkin_ptr")}</p>
          <div className="mt-3 lg:w-1/3 w-full">
            <SelectInput<BaseSelectItem, false>
              value={{
                label:
                  checkins?.results?.find(
                    (checkin) => checkin.id === settingsData.cust_checkin_ptr
                  )?.objkey ?? "Vikey default",
                value:
                  checkins?.results?.find(
                    (checkin) => checkin.id === settingsData.cust_checkin_ptr
                  )?.id ?? 0,
              }}
              onChange={(item) => {
                if (item) {
                  changeData("cust_checkin_ptr", item.value, true);
                }
              }}
              isLoading={isLoadingCheckins}
              placeholder={t("Seleziona il check-in")}
              options={
                checkins?.results
                  ? [
                      ...checkins.results.map((c) => {
                        return {
                          label: c.objkey,
                          value: c.id,
                        };
                      }),
                      {
                        label: "Vikey default",
                        value: 0,
                      },
                    ]
                  : []
              }
            />
          </div>
        </div>
      </>
      {/* COMUNICAZIONE  */}
      <div className="border-b border-gray-300 h-full my-5"></div>
      {/* BUROCRAZIA */}
      <>
        <div className="text-cyan-600 text-lg italic font-semibold">
          {t("apartmentSettings.burocracy")}
        </div>
        <div className="my-3 space-y-2 text-sm font-semibold">
          <p>{getByTitle(country_code, "title")}</p>
        </div>
        {/* CHECKBOX LIST */}
        <>
          <div className="text-sm mt-2">
            <Checkbox
              className={"max-w-max"}
              label={t("apartmentSettings.chk_documents")}
              checked={settingsData.chk_documents}
              onChange={() => {
                changeData("chk_documents");
              }}
            />
          </div>
          <div className="text-sm">
            <Checkbox
              className={"max-w-max"}
              label={t("apartmentSettings.chk_asknum_guests")}
              disabled={!settingsData.chk_documents}
              checked={
                settingsData.chk_documents && settingsData.chk_asknumguests
              }
              onChange={() => {
                changeData("chk_asknumguests");
              }}
            />
          </div>
          <div className="text-sm">
            <Checkbox
              className={"max-w-max"}
              label={t("apartmentSettings.chk_photo")}
              disabled={!settingsData.chk_documents}
              checked={settingsData.chk_documents && settingsData.chk_photo}
              onChange={() => {
                changeData("chk_photo");
              }}
            />
          </div>
          <div className="text-sm">
            <Checkbox
              className={"max-w-max"}
              label={t("apartmentSettings.chk_photo_all")}
              disabled={!settingsData.chk_documents || !settingsData.chk_photo}
              checked={
                settingsData.chk_documents &&
                settingsData.chk_photo &&
                settingsData.chk_photo_all
              }
              onChange={() => {
                changeData("chk_photo_all");
              }}
            />
          </div>
        </>
        {/* CHECKBOX LIST */}
        {/* CARD RECOGNITION ISTAT PA PAYTOURIST */}
        <div className="lg:flex justify-between shadow-md bg-cyan-200 px-8 py-4 rounded-xl mt-3">
          <div className="text-sm">
            <p>{t("apartmentSettings.activeRecognition1")}</p>
            <p className="text-xs">
              {t("apartmentSettings.activeRecognition2")}
            </p>
            <div className="text-sm mt-2 lowercase">
              <Checkbox
                className={"max-w-max"}
                label={t("resv.activeRecognition")}
                disabled={
                  !settingsData.chk_documents ||
                  moduleList.find(
                    (mod) => mod.linkId === moduleId.docs_auto_confirmation
                  )?.disableButton
                }
                checked={
                  settingsData.chk_documents &&
                  settingsData.chk_docs_auto_confirmation
                }
                onChange={() => {
                  changeData("chk_docs_auto_confirmation");
                }}
              />
            </div>
          </div>
          <div className="lg:mt-0 mt-3">
            <Button
              // gtmClass={`impostazioni_attiva_modulo_${moduleId.docs_auto_confirmation}_${country_code.toLowerCase()}`}
              disabled={
                !moduleList.find(
                  (mod) => mod.linkId === moduleId.docs_auto_confirmation
                )?.disableButton
              }
              label={
                moduleList.find(
                  (mod) => mod.linkId === moduleId.docs_auto_confirmation
                )?.disableButton
                  ? `${t("apartmentSettings.activeModule")!}`
                  : t("apartmentSettings.moduleAlreadyActive")
              }
              size={"small"}
              onClick={() => {
                openModalView(moduleId.docs_auto_confirmation);
              }}
            />
          </div>
        </div>
        <div className="shadow-md bg-cyan-200 px-8 py-4 rounded-xl mt-3">
          <div className="text-sm">
            <p>{t("apartmentSettings.iterBurocracy")}</p>

            <div className="md:flex justify-between mt-3">
              <p className="text-xs">{getByTitle(country_code, "subtitle")}</p>
              <div>
                <Button
                  // gtmClass={`impostazioni_attiva_modulo_${moduleId.pa}_${country_code.toLowerCase()}`}
                  label={
                    moduleList.find((mod) => mod.linkId === moduleId.pa)
                      ?.disableButton
                      ? `${t("apartmentSettings.activeModule")!}`
                      : t("apartmentSettings.moduleAlreadyActive")
                  }
                  disabled={
                    !moduleList.find((mod) => mod.linkId === moduleId.pa)
                      ?.disableButton
                  }
                  size={"small"}
                  onClick={() => {
                    openModalView(moduleId.pa);
                  }}
                />
              </div>
            </div>
            {/* SOLO SE ITALIA */}
            {country_code === "IT" && (
              <div className="md:flex justify-between mt-5">
                <p className="text-xs">
                  {t("apartmentSettings.iterBurocracyAutoIstat")}
                </p>
                <div>
                  <Button
                    // gtmClass={`impostazioni_attiva_modulo_${moduleId.auto_istat}_${country_code.toLowerCase()}`}
                    label={
                      moduleList.find(
                        (mod) => mod.linkId === moduleId.auto_istat
                      )?.disableButton
                        ? `${t("apartmentSettings.activeModule")!}`
                        : t("apartmentSettings.moduleAlreadyActive")
                    }
                    disabled={
                      !moduleList.find(
                        (mod) => mod.linkId === moduleId.auto_istat
                      )?.disableButton
                    }
                    size={"small"}
                    onClick={() => {
                      openModalView(moduleId.auto_istat);
                    }}
                  />
                </div>
              </div>
            )}
            {country_code === "IT" && (
              <div className="md:flex justify-between mt-5">
                <p className="text-xs">
                  {t("apartmentSettings.iterBurocracyPaytourist")}
                </p>
                <div>
                  <Button
                    // gtmClass={`impostazioni_attiva_modulo_${moduleId.paytourist}_${country_code.toLowerCase()}`}
                    label={
                      moduleList.find(
                        (mod) => mod.linkId === moduleId.paytourist
                      )?.disableButton
                        ? `${t("apartmentSettings.activeModule")!}`
                        : t("apartmentSettings.moduleAlreadyActive")
                    }
                    disabled={
                      !moduleList.find(
                        (mod) => mod.linkId === moduleId.paytourist
                      )?.disableButton
                    }
                    size={"small"}
                    onClick={() => {
                      openModalView(moduleId.paytourist);
                    }}
                  />
                </div>
              </div>
            )}
            {/* SOLO SE ITALIA */}
          </div>
        </div>
        {/* CARD RECOGNITION ISTAT PA PAYTOURIST */}
      </>
      {/* BUROCRAZIA */}
      <div className="border-b border-gray-300 h-full my-5"></div>
      {/* PAGAMENTI CHECK-IN ONLINE */}
      <>
        <div className="text-cyan-600 text-lg italic font-semibold">
          {t("apartmentSettings.wantPaymentCheckin")}
        </div>
        <div className="my-3 space-y-2 text-sm">
          <div>
            {t("apartmentSettings.wantPaymentCheckin1")}{" "}
            <a
              target={"_blank"}
              href="https://support.vikey.it/it/"
              rel="noreferrer"
              className="font-semibold hover:underline"
            >
              {t("apartmentSettings.clickRead")}
            </a>
          </div>
        </div>
        <div className="my-3 space-y-2 text-sm font-semibold">
          <p>{t("apartmentSettings.cityTaxPayment")}</p>
        </div>
        {/* CHECKBOX LIST */}
        <>
          <div className="text-sm mt-2">
            <Checkbox
              className={"max-w-max"}
              label={t("apartmentSettings.city_tax")}
              disabled={
                moduleList.find((mod) => mod.linkId === moduleId.city_tax)
                  ?.disableButton
              }
              checked={settingsData.chk_citytax}
              onChange={() => {
                changeData("chk_citytax");
              }}
            />
          </div>
          <div className="text-sm">
            <Checkbox
              className={"max-w-max"}
              label={t("apartmentSettings.chk_citytax_chargeguest")}
              disabled={
                !settingsData.chk_citytax ||
                moduleList.find((mod) => mod.linkId === moduleId.city_tax)
                  ?.disableButton
              }
              checked={
                settingsData.chk_citytax && settingsData.chk_citytax_chargeguest
              }
              onChange={() => {
                changeData("chk_citytax_chargeguest");
              }}
            />
          </div>
          <div className="flex mt-3 align-middle items-center gap-5 text-xs">
            <p>{t("apartmentSettings.city_tax_notActive")}</p>
            <div>
              <Button
                // gtmClass={`impostazioni_attiva_modulo_${moduleId.city_tax}_${country_code.toLowerCase()}`}
                label={
                  moduleList.find((mod) => mod.linkId === moduleId.city_tax)
                    ?.disableButton
                    ? `${t("apartmentSettings.activeModule")!}`
                    : t("apartmentSettings.moduleAlreadyActive")
                }
                disabled={
                  !moduleList.find((mod) => mod.linkId === moduleId.city_tax)
                    ?.disableButton
                }
                size={"small"}
                onClick={() => {
                  openModalView(moduleId.city_tax);
                }}
              />
            </div>
          </div>
        </>

        {/* CHECKBOX LIST */}
        {dataModules?.modules?.map((module: any) => {
          if (module.id === moduleId.deposit) {
            return (
              <>
                <div className="my-3 space-y-2 text-sm font-semibold">
                  <p>{t("apartmentSettings.depositPayment")}</p>
                </div>
                <Checkbox
                  className={"max-w-max"}
                  label={t("apartmentSettings.deposit")}
                  disabled={
                    moduleList.find((mod) => mod.linkId === moduleId.deposit)
                      ?.disableButton
                  }
                  checked={settingsData.chk_deposit}
                  onChange={() => {
                    changeData("chk_deposit");
                  }}
                />
                <div className="text-sm mt-2">
                  {settingsData.chk_deposit && (
                    <div className="space-y-4 ml-10 mt-4 mb-4 p-4 border border-gray-300 shadow-inner rounded-2xl  bg-white">
                      <FormField label={t("apartmentSettings.deposit_amount")}>
                        <TextInput
                          type="number"
                          value={settingsData.deposit_amount}
                          onChange={(value) =>
                            changeData("deposit_amount", value)
                          }
                          numbered={true}
                        ></TextInput>
                      </FormField>
                    </div>
                  )}
                </div>
                <div className="text-sm">
                  <Checkbox
                    className={"max-w-max"}
                    label={t("apartmentSettings.chk_deposit_chargeguest")}
                    disabled={
                      !settingsData.chk_deposit ||
                      moduleList.find((mod) => mod.linkId === moduleId.deposit)
                        ?.disableButton
                    }
                    checked={
                      settingsData.chk_deposit &&
                      settingsData.chk_deposit_chargeguest
                    }
                    onChange={() => {
                      changeData("chk_deposit_chargeguest");
                    }}
                  />
                </div>
                <div className="flex mt-3 align-middle items-center gap-5 text-xs">
                  <p>{t("apartmentSettings.deposit_notActive")}</p>
                  <div>
                    <Button
                      // gtmClass={`impostazioni_attiva_modulo_${moduleId.city_tax}_${country_code.toLowerCase()}`}
                      label={
                        moduleList.find(
                          (mod) => mod.linkId === moduleId.deposit
                        )?.disableButton
                          ? `${t("apartmentSettings.activeModule")!}`
                          : t("apartmentSettings.moduleAlreadyActive")
                      }
                      disabled={
                        !moduleList.find(
                          (mod) => mod.linkId === moduleId.deposit
                        )?.disableButton
                      }
                      size={"small"}
                      onClick={() => {
                        openModalView(moduleId.deposit);
                      }}
                    />
                  </div>
                </div>
              </>
            );
          }
          return null;
        })}

        {/* CHECKBOX LIST */}
        <div className="my-3 space-y-2 text-sm font-semibold">
          <p>{t("apartmentSettings.payStay")}</p>
        </div>
        {/* CHECKBOX LIST */}
        <>
          <div className="text-sm mt-2">
            <Checkbox
              className={"max-w-max"}
              label={t("apartmentSettings.chk_paystay")}
              checked={settingsData.chk_paystay}
              onChange={() => {
                changeData("chk_paystay");
              }}
            />
          </div>
          <div className="text-sm">
            <Checkbox
              className={"max-w-max"}
              label={t("apartmentSettings.chk_paystay_chargeguest")}
              disabled={!settingsData.chk_paystay}
              checked={
                settingsData.chk_paystay && settingsData.chk_paystay_chargeguest
              }
              onChange={() => {
                changeData("chk_paystay_chargeguest");
              }}
            />
          </div>
        </>
        {/* CHECKBOX LIST */}
      </>
      {/* PAGAMENTI CHECK-IN ONLINE */}
      <div className="border-b border-gray-300 h-full my-5"></div>
      {/* CONTRATTI */}
      <>
        <div className="text-cyan-600 text-lg italic font-semibold">
          {t("apartmentSettings.contractInvoice1")}
        </div>
        <div className="my-3 space-y-2 text-sm">
          <div>
            {t("apartmentSettings.contractInvoice2")}{" "}
            <a
              target={"_blank"}
              href="https://support.vikey.it/it/"
              rel="noreferrer"
              className="font-semibold hover:underline"
            >
              {t("apartmentSettings.clickRead")}
            </a>
          </div>
        </div>
        <div className="text-sm lg:w-1/3 w-full">
          <SelectInput<BaseSelectItem, false>
            // value cust_contract_ptr
            isDisabled={
              moduleList.find((mod) => mod.linkId === moduleId.contract)
                ?.disableButton
            }
            placeholder={"Scegli un contratto.."}
            isLoading={isLoadingContracts}
            value={{
              label:
                contracts?.results?.find(
                  (contract) => contract.id === settingsData.cust_contract_ptr
                )?.objkey ?? "Vikey default",
              value:
                contracts?.results?.find(
                  (contract) => contract.id === settingsData.cust_contract_ptr
                )?.id ?? 0,
            }}
            onChange={(item) => {
              if (item) {
                changeData("cust_contract_ptr", item.value, true);
              }
            }}
            options={
              contracts?.results
                ? [
                    ...contracts.results.map((c) => {
                      return {
                        label: c.objkey,
                        value: c.id,
                      };
                    }),
                  ]
                : []
            }
          />
        </div>
        {/* CHECKBOX LIST */}
        <>
          <div className="text-sm mt-2">
            <Checkbox
              className={"max-w-max"}
              label={t("apartmentSettings.chk_contract")}
              checked={settingsData.chk_contract}
              disabled={
                moduleList.find((mod) => mod.linkId === moduleId.contract)
                  ?.disableButton
              }
              onChange={() => {
                changeData("chk_contract");
              }}
            />
          </div>
          <div className="text-sm">
            <Checkbox
              className={"max-w-max"}
              label={t("apartmentSettings.chk_otp")}
              disabled={
                moduleList.find((mod) => mod.linkId === moduleId.contract)
                  ?.disableButton
              }
              checked={settingsData.chk_otp}
              onChange={() => {
                changeData("chk_otp");
              }}
            />
          </div>
          <div className="flex mt-3 align-middle items-center gap-5 text-xs">
            <p>{t("apartmentSettings.chk_contract_notActive")}</p>
            <div>
              <Button
                // gtmClass={`impostazioni_attiva_modulo_${moduleId.contract}_${country_code.toLowerCase()}`}
                label={
                  moduleList.find((mod) => mod.linkId === moduleId.contract)
                    ?.disableButton
                    ? `${t("apartmentSettings.activeModule")!}`
                    : t("apartmentSettings.moduleAlreadyActive")
                }
                disabled={
                  !moduleList.find((mod) => mod.linkId === moduleId.contract)
                    ?.disableButton
                }
                size={"small"}
                onClick={() => {
                  openModalView(moduleId.contract);
                }}
              />
            </div>
          </div>
          <div className="text-sm mt-3">
            <Checkbox
              className={"max-w-max"}
              label={t("apartmentSettings.require_invoice")}
              checked={settingsData.require_invoice}
              onChange={() => {
                changeData("require_invoice");
              }}
            />
          </div>
        </>
        {/* CHECKBOX LIST */}
      </>
      {/* CONTRATTI */}
      <div className="border-b border-gray-300 h-full my-3"></div>
      {/* SAVE EDIT */}
      <div className="mt-5 flex justify-center">
        <Button
          label={t("apartmentSettings.saveEdit")}
          loading={sendSettingsMutation.isLoading}
          size={"medium"}
          onClick={() => {
            sendSettingsMutation.mutate({
              local_key: local_key,
              chk_mail: settingsData.chk_mail,
              chk_sms: settingsData.chk_sms,
              chk_sms_done: settingsData.chk_sms_done,
              chk_cc: settingsData.chk_mail && settingsData.chk_cc,
              chk_documents: settingsData.chk_documents,
              chk_asknumguests:
                settingsData.chk_documents && settingsData.chk_asknumguests,
              chk_contract: settingsData.chk_contract,
              chk_otp: settingsData.chk_otp,
              chk_photo: settingsData.chk_documents && settingsData.chk_photo,
              chk_photo_all:
                settingsData.chk_documents &&
                settingsData.chk_photo &&
                settingsData.chk_photo_all,
              chk_citytax: settingsData.chk_citytax,
              chk_citytax_chargeguest:
                settingsData.chk_citytax &&
                settingsData.chk_citytax_chargeguest,
              chk_paystay: settingsData.chk_paystay,
              chk_paystay_chargeguest:
                settingsData.chk_paystay &&
                settingsData.chk_paystay_chargeguest,
              chk_deposit: settingsData.chk_deposit,
              deposit_amount: settingsData.deposit_amount,
              chk_deposit_chargeguest:
                settingsData.chk_deposit &&
                settingsData.chk_deposit_chargeguest,
              chk_whatsapp: settingsData.chk_whatsapp,
              chk_whatsapp_done: settingsData.chk_whatsapp_done,
              chk_docs_auto_confirmation:
                settingsData.chk_documents &&
                settingsData.chk_docs_auto_confirmation,
              require_invoice: settingsData.require_invoice,
              cust_email_ptr: settingsData.cust_email_ptr ?? 0,
              cust_checkin_ptr: settingsData.cust_checkin_ptr ?? 0,
              cust_contract_ptr: settingsData.cust_contract_ptr ?? 0,
              notify_now: settingsData.chk_mail && settingsData.notify_now,
              notify_1days: settingsData.chk_mail && settingsData.notify_1days,
              notify_3days: settingsData.chk_mail && settingsData.notify_3days,
              notify_7days: settingsData.chk_mail && settingsData.notify_7days,
              notify_done_1days:
                settingsData.chk_mail && settingsData.notify_done_1days,
              notify_mail_guest_active:
                settingsData.chk_mail && settingsData.notify_mail_guest_active,
            });
          }}
        />
      </div>
      {/* SAVE EDIT */}
      {/* MODALE MODULI */}
      <Modal visible={!!modalView.id}>
        <ModalCard
          title={`${t("apartmentSettings.activeModule").toUpperCase()} - ${t(
            `module.id${modalView.id}`
          ).toUpperCase()}`}
          className="w-full"
        >
          <ModalSection>
            <div className="text-gray-700 p-4 pt-0 border-gray-300">
              <ModuleCard
                moduleName={t(`module.id${modalView.id}`).toUpperCase()}
                active={modalView.active}
                price={modalView.price}
                defaultPrice={modalView.defaultPrice}
                onChange={() => {
                  setModalView((prev) => {
                    if (prev.active) {
                      return {
                        ...prev,
                        active: !prev.active,
                        confirmValue: "",
                      };
                    }
                    return {
                      ...prev,
                      active: !prev.active,
                    };
                  });
                }}
                priceCycles={modalView.priceCycles}
              />
            </div>
            {modalView.active && (
              <div className="text-gray-700 p-4 pt-0 border-gray-300">
                {t("general.write1")}
                <span className={"font-bold"}>{confirmationKey}</span>{" "}
                {t("general.write2")}
                <div className="mt-2">
                  <TextInput
                    value={modalView.confirmValue}
                    onChange={(val) =>
                      setModalView((prev) => {
                        return {
                          ...prev,
                          confirmValue: val,
                        };
                      })
                    }
                  />
                </div>
              </div>
            )}
          </ModalSection>
          <ModalActions
            isLoading={activateMutation.isLoading}
            onSave={() => {
              if (modalView.confirmValue === confirmationKey) {
                activateMutation.mutate({
                  local_keys: [local_key],
                  module_id: modalView.id as number,
                });
              }
            }}
            saveLabel={t("general.enable")}
            saveDisabled={modalView.confirmValue !== confirmationKey}
            onClose={() => {
              setModalView({
                id: null,
                confirmValue: "",
                active: false,
                price: 0,
                defaultPrice: 0,
                priceCycles: 999,
              });
            }}
          />
        </ModalCard>
      </Modal>
      {/* MODALE MODULI */}
    </>
  );
};

export default ApartmentSettings;
