import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useMutation } from "react-query";
import Button from "../Button";
import { fetchAttachments, translateSingleMessage } from "../../shared/queries";
import { Dictionary } from "@reduxjs/toolkit";
import CircleSpinner from "../CircleSpinner";
import { Message, MessageAttachments } from "../../types";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { ImageViewModal } from "../ImageViewModal";
import Attachments from "./Attachments";

import { sendMessage } from "../../features/messaging/messagingSlice";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../Modal";
import ModalCard from "../ModalCard";
import MessageInput from "./MessageInput";
import PageWrapper from "../PageWrapper";
import PageTitle from "../PageTitle";
import store from "../../store";
import Checkbox from "../Checkbox";

interface MessageBubbleProps {
  message:Message;
  resv_key: string;
  profile_lang: string | undefined;
  handleBotMessage: (
    action: string,
    message_id: string,
    message_content: string,
    use_for_learnig?:boolean
  ) => void;
  isSending?: boolean;
  thread_id?:string;
}

const MessageBubble: React.FC<MessageBubbleProps> = ({
  message,
  profile_lang,
  resv_key,
  handleBotMessage,
  isSending,
  thread_id
}) => {
  const { t } = useTranslation();
  const {
    messages,
  } = useSelector((state) => store.getState().messageStore);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const dispatch = useDispatch();
  const [botMessage, setBotMessage] = useState(message.content);
  const [botMessageUseForLearning,setBotMessageUseForLearning] = useState(false)

  const [botMessageModalVisible, setBotMessageModal] = useState(false);

  const [translated, setTranslated] = useState(false);
  const [localTranslations, setLocalTranslations] =
    useState(message.translations);
  const [displayedAttachments, setDisplayedAttachments] = useState(message.attachments);
  // console.log("Attachment ",attachments)
  const { mutate: translateMessage, isLoading: isTranslating } = useMutation(
    () => translateSingleMessage(message.message_id, profile_lang),
    {
      onSuccess: (translatedMessage) => {
        if (translatedMessage?.translations) {
          setLocalTranslations((prev) => ({
            ...prev,
            ...translatedMessage.translations,
          }));
        }
        setTranslated(true);
      },
      onError: (error: Error) => {
        toast.error(
          error.message ? t(error.message) : t("general.requestError")
        );
      },
    }
  );

  const handleMessageTranslate = () => {
    if (profile_lang && localTranslations[profile_lang]) {
      setTranslated(!translated);
    } else {
      translateMessage();
    }
  };
  const selectedImageHandle = (relative_path: string) => {
    if (relative_path) {
      setSelectedImage(relative_path);
    }
  };

  const bubbleClass = classNames(
    "p-3 text-sm rounded-lg shadow-md transition-transform duration-200",
    {
      "bg-gray-300 text-black self-start hover:shadow-lg": message.type === "received",
      "bg-cyan-600 text-white self-end hover:shadow-lg": message.type === "sent-host",
      "bg-green-500 text-white self-end hover:shadow-lg": message.type === "sent-bot",
    }
  );

  const formatTime = (date: Date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes}`;
  };
  useEffect(() => {
    let isActive = true;

    const fetchAndConvertAttachments = async () => {
      const updatedAttachments = await Promise.all(
        message.attachments.map(async (attachment) => {
          try {
            const response = await fetchAttachments(resv_key, attachment.id);
            if (!response.ok) throw new Error("Failed to fetch attachment");

            const blob = await response.blob();
            const file_data = await new Promise<ArrayBuffer>(
              (resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result as ArrayBuffer);
                reader.onerror = () =>
                  reject("Failed to convert blob to ArrayBuffer");
                reader.readAsArrayBuffer(blob);
              }
            );
            const base64Data = Buffer.from(new Uint8Array(file_data)).toString(
              "base64"
            );
            const base64_string = `data:${blob.type};base64,${base64Data}`;
            return {
              ...attachment,
              relative_path: base64_string,
              is_fetching: false,
            };
          } catch (error) {
            console.error(`Error fetching attachment ${attachment.id}:`, error);
            return { ...attachment, is_fetching: false };
          }
        })
      );

      if (isActive) {
        setDisplayedAttachments(updatedAttachments);
      }
    };

    fetchAndConvertAttachments();

    return () => {
      isActive = false;
    };
  }, [message.attachments, resv_key]);

  return (
    <div
      className={`flex flex-col ${
        message.type === "received" ? "items-start" : "items-end"
      } mb-2`}
      style={{ maxWidth: "100%" }}
    >
      <div
        className={`flex ${
          message.type === "received" ? "items-center gap-2" : "justify-end"
        }`}
        style={{ width: "100%" }}
      >

        <div
          className={bubbleClass}
          style={{
            maxWidth: "75%",
            wordWrap: "break-word",
            overflowWrap: "break-word",
            whiteSpace: "pre-wrap",
          }}
        >
          
          {thread_id &&
            messages[thread_id].some((msg) =>
              message.replies_to?.some((replyId) => replyId === msg.message_id)
            ) && (
              <div
                style={{
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  whiteSpace: "pre-wrap",
                }}
              >
                {messages[thread_id].map((msg) => {
                  if (!message.replies_to?.includes(msg.message_id)) return null;

                  const bubbleClass = classNames(
                    "p-3 text-sm rounded-lg shadow-md transition-transform duration-200",
                    {
                      "bg-gray-300 text-black self-start hover:shadow-lg mb-2":
                        msg.type === "received",
                      "bg-cyan-600 text-white self-end hover:shadow-lg mb-2":
                        msg.type === "sent-host",
                      "bg-green-500 text-white self-end hover:shadow-lg mb-2":
                        msg.type === "sent-bot",
                    }
                  );

                  return (
                    <div
                      key={msg.message_id}
                      className={bubbleClass}
                      dangerouslySetInnerHTML={{
                        __html:
                          translated &&
                          profile_lang &&
                          localTranslations[profile_lang]
                            ? localTranslations[profile_lang].replace(/\n/g, "<br/>")
                            : msg.content.replace(/\n/g, "<br/>"),
                      }}
                    />
                  );
                })}
              </div>
            )}

          {message && (
            <div
              dangerouslySetInnerHTML={{
                __html:
                  translated && profile_lang && localTranslations[profile_lang]
                    ? localTranslations[profile_lang].replace(/\n/g, "<br/>")
                    : message.content?.replace(/\n/g, "<br/>"),
              }}
            />
          )}

          {displayedAttachments && displayedAttachments.length > 0 && (
            <div className="mt-2 space-y-1 max-w-[75%]">
              <Attachments
                attachments={displayedAttachments}
                onClickImage={selectedImageHandle}
                color={message.type !== "received" ? "white" : "primary"}
              />
            </div>
          )}
        </div>
        {message.type === "received" &&
          message &&
          (!isTranslating ? (
            <Button
              className="flex"
              color="info"
              icon={translated ? "translated" : "translate"}
              iconSize={"20px"}
              onClick={handleMessageTranslate}
            />
          ) : (
            <CircleSpinner color="primary" />
          ))}
      </div>
      <div
        className="text-xs mt-1 text-gray-500 items-end"
        style={{
          maxWidth: "75%",
          wordWrap: "break-word",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
        }}
      >
        {message.type === "sent-bot" ? "Bot " : ""}
        {message.approval_status !== 0 && formatTime(new Date(message.sent_on))}
      </div>
      {message.approval_status === 0 && (
        <div className="flex flex-wrap gap-2 mt-2 justify-end">
          {/* Approve Message Button */}
          <Button
            onClick={() =>
              handleBotMessage("CONFIRM_MESSAGE", message.message_id, message.content)
            }
            label={t("assistant.approveMessage")}
            size="small"
            color="primary"
            icon="check"
            className="px-3 py-1 rounded-md shadow-md hover:bg-green-600 hover:text-white"
          />

          {/* Improve and Approve Message Button */}
          <Button
            onClick={() => setBotMessageModal(!botMessageModalVisible)}
            label={t("assistant.improveAndApproveMessage")}
            size="small"
            color="primary"
            icon="pencil"
            className="px-3 py-1 rounded-md shadow-md hover:bg-yellow-500 hover:text-white hover:no-underline"
          />

          {/* Bounce Button */}
          <Button
            onClick={() => handleBotMessage("BOUNCE_MESSAGE", message.message_id, message.content)
            }
            label={t("assistant.bounceMessage")}
            size="small"
            color="negative"
            icon="close"
            className="px-3 py-1 rounded-md shadow-md hover:bg-red-600"
          />
        </div>
      )}

      {/* {attachments.length === 1 && (
          <ImageViewModal
            visible={!!selectedImage}
            onClose={() => setSelectedImage(null)} // Close modal
          >
            {selectedImage && (
              <img
                src={selectedImage}
                alt="Preview"
                className="w-full h-full max-w-full max-h-full p-10 object-contain"
                style={{ objectFit: "contain", height: "85vh" }}
              />
            )}
          </ImageViewModal>
        )}
           */}

      <Modal
        visible={botMessageModalVisible}
        className="w-full"
        skipPreventDefualt={true}
      >
        <PageWrapper
          className="bg-white p-4 rounded-2xl w-full lg:w-2/3"
          style={{ maxHeight: "85vh" }}
        >
          <div className="flex flex-wrap items-center justify-between w-full sm:space-x-4">
            <PageTitle
              title={t("assistant.improveAndApproveMessage")}
              className="w-full sm:w-auto"
            />
          </div>
          <Checkbox label={t("messaging.assistant.useForLearning")} checked={botMessageUseForLearning} onChange={()=>{setBotMessageUseForLearning(!botMessageUseForLearning)}}/>
          <MessageInput
            value={botMessage}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setBotMessage(e.target.value)
            }
            placeholder={t("messaging.typeMessage")}
            className="flex-1 rounded-lg p-2 w-full"
            emoji_lang={profile_lang}
            disabled={isSending}
            rows={10}
            no_attachments={true}
          />
          <div className="w-full sm:w-auto flex justify-start mt-2 sm:mt-0">
            <Button
              onClick={() => {
                setBotMessage(message.content);
                setBotMessageModal(!botMessageModalVisible);
              }}
              color="info"
              className="mr-2 text-red-500"
              label={t("general.cancel")}
            ></Button>
            <Button
              onClick={() => {
                handleBotMessage(
                  "IMPROVE_AND_CONFIRM",
                  message.message_id,
                  botMessage,
                  botMessageUseForLearning
                );
                setBotMessageModal(!botMessageModalVisible);
              }}
              className="text-white-600 bg-white hover:bg-cyan-400 focus:outline-none rounded-full p-2"
              label={t("assistant.approveMessage")}
              icon="check"
            ></Button>
          </div>
        </PageWrapper>
      </Modal>
    </div>
  );
};

export default React.memo(MessageBubble);
